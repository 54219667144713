<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="row">
          <h1 class="col-sm-12">Contrato firmado</h1>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12">
            Indica si la firma se llevo a cabo de manera correcta
          </div>
        </div>
        <div class="row form-group">
          <label for="firma_contrato" class="col-form-label col-sm-3">Fecha a ingreso RPP</label>
          <div class="col-sm-9">
            <input v-model="fechas.firma_contrato" type="date" name="firma_contrato" id="firma_contrato" class="form-control">
          </div>
        </div>
        <div class="row form-group">
          <label for="incripcion_rpp" class="col-form-label col-sm-3">Fecha de inscripción RPP</label>
          <div class="col-sm-9">
            <input v-model="fechas.incripcion_rpp" type="date" name="incripcion_rpp" id="incripcion_rpp" class="form-control">
          </div>
        </div>
        <div class="row form-group">
          <label for="entrega_bpb" class="col-form-label col-sm-3">Fecha de entrega a Bien para bien</label>
          <div class="col-sm-9">
            <input v-model="fechas.entrega_bpb" type="date" name="entrega_bpb" id="entrega_bpb" class="form-control">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-success" @click="avanzar">Finalizar proceso</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        fechas: {
          firma_contrato: null
          ,incripcion_rpp: null
          ,entrega_bpb: null
        }
      }
    }
    ,methods: {
      async avanzar() {
        try {
          if (!this.fechas.firma_contrato)
            return this.$helper.showMessage('Error','Debes definir la fecha de la firma de contrato','error','alert');
          
          if (!this.fechas.incripcion_rpp)
            return this.$helper.showMessage('Error','Debes definir la fecha de inscripción de RPP','error','alert');
          
          if (!this.fechas.entrega_bpb)
            return this.$helper.showMessage('Error','Debes definir la fecha de la entrega de Bien para bien','error','alert');

          let res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {
            estatus_notario: 2
            ,firma_contrato: this.fechas.firma_contrato
            ,incripcion_rpp: this.fechas.incripcion_rpp
            ,entrega_bpb: this.fechas.entrega_bpb
          })).data;
          this.$log.info('res', res)

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  label {
    background-color: #4C4C4C;
    color: #fff;
  }
</style>